import React from 'react';
import './Legal.css';

const Legal = () => {
    return (
        <div className="legal-container">
            <div className="legal-content">
                <h2>YT Copycat Legal Notices</h2>
                <p>
                    YT Copycat is a Software as a Service operated by Fabien BOCO, a French auto-entrepreneur, located at Saverdun, 09700 France.<br/><br/>
                    Pursuant to Article 6 of Law No. 2004-575 of June 21, 2004 on confidence in the digital economy, users of the nova-prospect.com site are informed of the identity of the various parties involved in its creation and monitoring:<br/><br/>
                    Business Name: BOCO<br/><br/>
                    RCS (Trade and Companies Register) Number: 095311082<br/>
                    Status: Sole Proprietorship<br/>
                    SIRET Number: 89531108200013<br/>
                    Address: 31770 Colomiers, France<br/>
                    Phone Number: 0756835116<br/>
                    E-mail: contact@ytcopycat.com<br/>
                    Publication Manager's E-mail: contact@ytcopycat.com
                </p>

                <h3>Hosting Service Provider</h3>
                <p>The website is hosted by o2switch located at 222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France.</p>

                <h3>Processor of Payment</h3>
                <p>Payment processing services for YT Copycat are provided by Stripe and are subject to the Stripe Connected Account Agreement, which includes the Stripe Terms of Service (collectively, the "Stripe Services Agreement"). By agreeing to these terms or continuing to operate as a YT Copycat customer, you agree to be bound by the Stripe Services Agreement, as the same may be modified by Stripe from time to time. As a condition of YT Copycat enabling payment processing services through Stripe, you agree to provide YT Copycat accurate and complete information about you and your business, and you authorize YT Copycat to share it and transaction information related to your use of the payment processing services provided by Stripe.</p>

                <h2>Terms of Use</h2>
                <p>These Terms of Use govern your use of the YT Copycat website (the "Site") and the services provided by YT Copycat ("YT Copycat Services"), which include the Software as a Service that allows users to convert YouTube videos into textual content such as summaries, blog articles, or tweets.</p>

                <h3>Use of Service</h3>
                <p>YT Copycat provides a Software as a Service that allows users to convert YouTube videos into textual content such as summaries, blog articles, or tweets. Users may sign up and pay for a subscription to access unlimited use of the service. Once a subscription is purchased, it is non-refundable unless otherwise determined by YT Copycat's support team.</p>

                <h3>Prohibited Use of YT Copycat</h3>
                <p>Use of YT Copycat must be lawful and in accordance with the terms set out herein. Any use of the platform that violates these Terms of Use, including, without limitation, the following, is expressly prohibited:</p>

                <h4>a) Unauthorized Automation:</h4>
                <p>The use of bots, scripts, or any other automated process to interact with YT Copycat's services or to automate or accelerate the content generation process is strictly forbidden. YT Copycat has been designed to function with human interaction, and attempts to circumvent this requirement are a direct violation of these Terms of Use.</p>

                <h4>b) Breach of Normal Functionality:</h4>
                <p>Any action that attempts to disrupt, undermine, manipulate or otherwise interfere with the normal operation of YT Copycat's services is prohibited. This includes, but is not limited to, actions that may impose an unreasonable or disproportionately large load on our infrastructure or detrimentally interfere with, intercept, or expropriate any system, data, or information of YT Copycat.</p>

                <h3>Privacy Policy</h3>
                <p>Last updated: 25 March 2023</p>
                <p>YT Copycat ("we", "us", "our") is committed to protecting the privacy of our users ("you", "your"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our service.</p>

                <h3>Information We Collect</h3>
                <p>When you register for an account on our Service, we may collect the following information:</p>
                <ul>
                    <li>Email address</li>
                    <li>Encrypted password</li>
                </ul>

                <h3>Contact Us</h3>
                <p>If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact us at:</p>
                <ul>
                    <li>Email: contact@ytcopycat.com</li>
                </ul>
            </div>
        </div>
    );
};

export default Legal;
