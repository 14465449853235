import React from 'react';
import Popup from './Popup';
import './ConfirmDialog.css';

const ConfirmDialog = ({ isOpen, onClose, onConfirm, title, message }) => {
    if (!isOpen) return null;

    return (
        <Popup onClose={onClose}>
            <div className="confirm-dialog">
                <h3>{title}</h3>
                <p>{message}</p>
                <div className="confirm-actions">
                    <button className="button-hollow" onClick={onClose}>Cancel</button>
                    <button className="button-danger" onClick={onConfirm}>Delete Account</button>
                </div>
            </div>
        </Popup>
    );
};

export default ConfirmDialog; 