import React, { useState } from 'react';
import Popup from '../../UI/Popup';
import LoadingButton from '../../UI/LoadingButton';
import './SavePromptDialog.css';

const SavePromptDialog = ({ onClose, onSave, initialName = '' }) => {
    const [name, setName] = useState(initialName);
    const [isSaving, setIsSaving] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
            await onSave(name);
            onClose();
        } catch (error) {
            console.error('Error saving prompt:', error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Popup onClose={onClose}>
            <div className="save-prompt-dialog">
                <h2>Save Custom Prompt</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="promptName">Prompt Name</label>
                        <input
                            type="text"
                            id="promptName"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder="Enter a name for your prompt"
                            required
                        />
                    </div>
                    <div className="dialog-actions">
                        <button type="button" className="button-hollow" onClick={onClose}>
                            Cancel
                        </button>
                        <LoadingButton
                            type="submit"
                            className="button-plain"
                            isLoading={isSaving}
                        >
                            Save
                        </LoadingButton>
                    </div>
                </form>
            </div>
        </Popup>
    );
};

export default SavePromptDialog; 