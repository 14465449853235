// App.js
import React, { useState, useContext } from 'react';
import Sidebar from './components/App/Sidebar';
import BottomMenu from './components/App/BottomMenu';
import Settings from './components/App/Settings';
import BlogGenerator from './components/App/Generator/BlogGenerator';
import TweetGenerator from './components/App/Generator/TweetGenerator';
import NewsletterGenerator from './components/App/Generator/NewsletterGenerator';
import LinkedinGenerator from './components/App/Generator/LinkedinGenerator';
import CustomGenerator from './components/App/Generator/CustomGenerator';
import SummaryGenerator from './components/App/Generator/SummaryGenerator';
import { AuthContext } from './components/Login/AuthContext';

import './App.css';
import PricingPopup from './components/App/PricingPopup';
import Generations from './components/App/Generations';

function App() {
    const [activeSection, setActiveSection] = useState('blog');
    const [selectedNoteId, setSelectedNoteId] = useState(null);
    const [selectedTodolistId, setSelectedTodolistId] = useState(null);
    const { isAuthenticated } = useContext(AuthContext);

    const [isPricingOpen, setIsPricingOpen] = useState(false);

    const handleSectionChange = (section) => {
        setActiveSection(section);
        setSelectedNoteId(null);
        setSelectedTodolistId(null);
    };

    const renderActiveSection = () => {
        switch (activeSection) {
            case 'blog':
                return <BlogGenerator />;
            case 'settings':
                return <Settings onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'generations':
                return <Generations />;
            case 'tweet':
                return <TweetGenerator onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'newsletter':
                return <NewsletterGenerator onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'linkedin':
                return <LinkedinGenerator onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'custom':
                return <CustomGenerator onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            case 'summary':
                return <SummaryGenerator onOpenPricing={() => {setIsPricingOpen(true)}}/>;
            default:
                return <Settings onOpenPricing={() => {setIsPricingOpen(true)}}/>;
        }
    };

    return (
        <>
            <div className="container-fluid" style={{ padding: 0 }}>
                {isAuthenticated && (
                    <div className='app'>
                        <Sidebar 
                            onSelect={handleSectionChange} 
                            selected={activeSection}
                            onOpenPricing={() => setIsPricingOpen(true)}
                        />

                        <div className="content-section">
                            {renderActiveSection()}
                        </div>
                        <BottomMenu onSelect={handleSectionChange} selected={activeSection} />
                    </div>
                )}
            </div>

            {isPricingOpen && (
                <PricingPopup onClose={() => setIsPricingOpen(false)} />
            )}
        </>
    );
}

export default App;
