import { Link } from 'react-router-dom';
import { useState } from 'react';

import './Navbar.css'

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    return (
        <div className="navbar">
            {/* Menu Desktop */}
            <div className="navbar-container desktop-menu">
                <div className="navbar-logo">
                    <img src="/logo_dark.png" alt="EchoNote Logo" />
                </div>

                <div className="navbar-links">
                    <a href="/">Home</a>
                    <a href="/pricing">Pricing</a>
                    <a href="/contact">Contact</a>
                    <Link to="/affiliate" className="nav-link">Affiliate</Link>
                </div>

                <div className="navbar-buttons">
                    <Link to="/login" className="no-style-link">
                        <button className="button-plain">
                            <span className="material-symbols-outlined icon">login</span>
                            Login
                        </button>
                    </Link>
                    <Link to="/signup" className="no-style-link">
                        <button className="button-hollow">
                            <span className="material-symbols-outlined icon">signature</span>
                            Signup
                        </button>
                    </Link>
                </div>
            </div>

            {/* Menu Mobile */}
            <div className="mobile-menu">
                <div className="mobile-menu-header">
                    <div className="navbar-logo">
                        <img src="/logo_dark.png" alt="EchoNote Logo" />
                    </div>
                    <button className="mobile-menu-icon" onClick={toggleMobileMenu}>
                        <span className="material-symbols-outlined">
                            {isMobileMenuOpen ? 'close' : 'menu'}
                        </span>
                    </button>
                </div>

                {isMobileMenuOpen && (
                    <div className="mobile-menu-content">
                        <div className="mobile-links">
                            <a href="/">Home</a>
                            <a href="/pricing">Pricing</a>
                            <a href="/contact">Contact</a>
                            <Link to="/affiliate" className="nav-link">Affiliate</Link>
                        </div>
                        <div className="mobile-buttons">
                            <Link to="/login" className="no-style-link">
                                <button className="button-plain">
                                    <span className="material-symbols-outlined icon">login</span>
                                    Login
                                </button>
                            </Link>
                            <Link to="/signup" className="no-style-link">
                                <button className="button-hollow">
                                    <span className="material-symbols-outlined icon">signature</span>
                                    Signup
                                </button>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Navbar;