import { useState, useRef } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from './Navbar';
import Footer from './Footer';
import Toast from '../UI/Toast';
import LoadingButton from '../UI/LoadingButton';
import './Contact.css';
import api from '../api';

const Contact = () => {
    const recaptchaRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [toast, setToast] = useState({
        show: false,
        type: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const token = await recaptchaRef.current.executeAsync();
            const dataToSend = {
                ...formData,
                recaptchaToken: token
            };

            const response = await api.post('/contact', dataToSend);

            setFormData({
                name: '',
                email: '',
                message: ''
            });

            setToast({
                show: true,
                type: 'success',
                message: response.data.message || 'Message sent successfully!'
            });

        } catch (error) {
            setToast({
                show: true,
                type: 'error',
                message: error.response?.data?.message || 'An error occurred while sending the message'
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="contact-page">
                <div className="contact-container">
                    <h1>Contact Us</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message</label>
                            <textarea
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                                rows="6"
                            />
                        </div>
                        <div className="recaptcha-container">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            />
                            <div className="recaptcha-terms">
                                This site is protected by reCAPTCHA and the Google
                                <a href="https://policies.google.com/privacy"> Privacy Policy</a> and
                                <a href="https://policies.google.com/terms"> Terms of Service</a> apply.
                            </div>
                        </div>
                        <LoadingButton
                            type="submit"
                            className="button-plain"
                            isLoading={isLoading}
                        >
                            <span className="material-symbols-outlined icon">send</span>
                            Send Message
                        </LoadingButton>

                    </form>
                </div>
            </div>
            <Footer />
            {toast.show && (
                <Toast
                    type={toast.type}
                    message={toast.message}
                    onClose={() => setToast({ ...toast, show: false })}
                />
            )}
        </>
    );
};

export default Contact; 