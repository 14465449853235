import React, { useState } from 'react';
import api from '../api';
import './Login.css'; // Utilisation du CSS de Login
import GoogleLogin from './GoogleLogin';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState({ type: '', text: '' });
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handleSignup = async (e) => {
        e.preventDefault();
        setIsButtonLoading(true);
        try {
            await api.post('/signup', { email });
            setMessage({
                type: 'success',
                text: 'An activation link has been sent to your email address. Please check your inbox (and spam folder) to activate your account.'
            });
        } catch (error) {
            console.error('Signup error:', error);
            setMessage({
                type: 'error',
                text: error.response?.data?.msg || 'An error occurred while creating your account. Please try again or contact support if the problem persists.'
            });
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src="logo_dark.png" alt="Logo" className="login-logo" />
                <h2>Create an account</h2>
                <p>Enter your email to create an account</p>

                <form onSubmit={handleSignup}>
                    <div className="input-group">
                        <div className="icon-container">
                            <span className="material-symbols-outlined login-icon">mail</span>
                        </div>
                        <input
                            type="email"
                            placeholder="your@example.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="checkbox-container">
                        <input type="checkbox" required />
                        <label>
                            I agree to the <a href="/legal" target="_blank">Terms and Conditions</a>
                        </label>
                    </div>

                    <div style={{ marginTop: '30px' }}>
                        <button className="button-plain" type="submit" disabled={isButtonLoading}>
                            <span className="material-symbols-outlined icon">email</span>
                            {isButtonLoading ? 'Requesting...' : 'Request activation email'}
                        </button>
                    </div>
                </form>

                <p className="login-toggle-text">
                    Already have an account? <strong onClick={() => window.location.href = '/login'}>Login</strong>
                </p>

                <div className="login-separator">
                    <span>or</span>
                </div>

                <GoogleLogin />

                {message.text && (
                    <div className={message.type === 'error' ? 'error-message' : 'success-message'}>
                        {message.text}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Signup;
