import React from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';

const LinkedinGenerator = ({ onOpenPricing }) => {
    const formFields = [
        LanguageSelect(false),
        {
            id: 'tone',
            type: 'select',
            label: 'Tone',
            defaultValue: 'Motivational',
            options: [
                { value: 'Motivational', label: 'Motivational 💪' },
                { value: 'Inspirational', label: 'Inspirational 🌟' },
                { value: 'Informative', label: 'Informative 📘' },
                { value: 'Persuasive', label: 'Persuasive 🗣️' },
                { value: 'Reflective', label: 'Reflective 🤔' },
                { value: 'Humorous', label: 'Humorous 😂' },
                { value: 'Serious', label: 'Serious ⚖️' },
                { value: 'Encouraging', label: 'Encouraging 👏' },
                { value: 'Neutral', label: 'Neutral 🔄' },
                { value: 'Thought-provoking', label: 'Thought-provoking 🧠' }
            ]
        },
        {
            id: 'style',
            type: 'select',
            label: 'Style',
            defaultValue: 'Professional',
            options: [
                { value: 'Professional', label: 'Professional 👔' },
                { value: 'Casual', label: 'Casual 🧢' },
                { value: 'Formal', label: 'Formal 🎩' },
                { value: 'Conversational', label: 'Conversational 💬' },
                { value: 'Storytelling', label: 'Storytelling 📖' },
                { value: 'Analytical', label: 'Analytical 📊' },
                { value: 'Direct', label: 'Direct ➡️' },
                { value: 'Friendly', label: 'Friendly 😊' },
                { value: 'Technical', label: 'Technical 🛠️' },
                { value: 'Creative', label: 'Creative 🎨' }
            ]
        },
        {
            id: 'length',
            type: 'select',
            label: 'Length',
            defaultValue: 'medium',
            options: [
                { value: 'short', label: 'Short' },
                { value: 'medium', label: 'Medium' },
                { value: 'long', label: 'Long' }
            ]
        },
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <Generator
            title={<><span className="highlight">YT</span> to LinkedIn</>}
            icon="fab fa-linkedin icon"
            formFields={formFields}
            generateEndpoint="/generate/youtube_linkedin"
            transformFormData={(data) => ({
                url: data.url,
                language: data.language,
                tone: data.tone,
                style: data.style,
                length: data.length,
                model: data.model
            })}
            onOpenPricing={onOpenPricing}
        />
    );
};

export default LinkedinGenerator; 