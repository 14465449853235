import React from 'react';
import { useContext } from 'react';
import { AuthContext } from './AuthContext';
import axios from 'axios';
import './GoogleLogin.css';
const GoogleLogin = () => {
    const { setIsAuthenticated, setUser, setSubscription } = useContext(AuthContext);

    const handleGoogleLogin = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/auth/google/login`,
                { withCredentials: true }
            );
            window.location.href = response.data.auth_url;
        } catch (error) {
            console.error('Erreur de connexion Google:', error);
        }
    };

    return (
        <button 
            className="button-google" 
            onClick={handleGoogleLogin}
            type="button"
            aria-label="Sign in with Google"
        >
            <div className="button-google-content">
                <svg className="google-icon" width="18" height="18" viewBox="0 0 18 18">
                    <path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18z"></path>
                    <path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17z"></path>
                    <path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18l2.67-2.07z"></path>
                    <path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.77 4.77 0 0 1 4.48-3.3z"></path>
                </svg>
                <span className="button-google-text">Sign in with Google</span>
            </div>
        </button>
    );
};

export default GoogleLogin; 