import React from 'react';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import Navbar from './Navbar';
import Footer from './Footer';
import './Affiliate.css';

const Affiliate = () => {
    return (
        <div className="affiliate-page">
            <Navbar />
                <div className="section hero-section">
                    <div className="hero-content">
                        <h1>Join the <span className="highlight">YT Copycat</span> Affiliate Program</h1>
                        <h4>Get paid on the Growing Demand for Content Automation</h4>
                        <p className="subtitle">Earn a 30% recurring lifetime commission by promoting YT Copycat today!</p>
                        <button 
                            className="button-important"
                            onClick={() => window.open("https://app.uppercut.co/join/ytc-team-b7ec1834-848d-45cf-9c3c-63a120d5f8e6/", "_blank")}
                        >
                            <div className="inner">Become an affiliate now</div>
                            
                        </button>
                    </div>
                </div>

                <div className="section benefits-section">
                    <div className="section-header">
                        <h4>BENEFITS</h4>
                        <h2>Why promote <span className="highlight">YT Copycat</span>?</h2>
                    </div>
                    <div className="features-grid">
                        <div className="feature-item">
                            <div className="icon">
                                <i className="bi bi-youtube"></i>
                            </div>
                            <div>
                                <h4>Massive Market</h4>
                                <p>YouTube is the second most visited website worldwide, with over 2 billion users. This massive audience creates an endless demand for content transformation tools.</p>
                            </div>
                        </div>

                        <div className="feature-item">
                            <div className="icon">
                                <i className="bi bi-recycle"></i>
                            </div>
                            <div>
                                <h4>Growing Demand</h4>
                                <p>The need for efficient content creation and recycling tools is higher than ever, with businesses striving for productivity and online presence.</p>
                            </div>
                        </div>

                        <div className="feature-item">
                            <div className="icon">
                                <i className="bi bi-filetype-ai"></i>
                            </div>
                            <div>
                                <h4>Early Opportunity</h4>
                                <p>Seize the opportunity to be among the first to promote a tool that leverages cutting-edge GPT-4 technology for content creation.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section cta-section">
                    <div className="cta-card">
                        <h2>Build monthly recurring revenue with <span className="highlight">YT Copycat</span></h2>
                        <button 
                            className="button-plain"
                            onClick={() => window.open("https://app.uppercut.co/join/ytc-team-b7ec1834-848d-45cf-9c3c-63a120d5f8e6/", "_blank")}
                        >
                            Join the affiliate program
                        </button>
                    </div>
                </div>

                <Footer />
                </div>
    );
};

export default Affiliate; 