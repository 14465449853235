import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../Page/Navbar';
import Footer from '../Page/Footer';
import './YTThumbnail.css';

const YTThumbnail = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');
    const [error, setError] = useState('');

    const getVideoId = (url) => {
        const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?.*v=|embed\/)|youtu\.be\/)([\w-]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const videoId = getVideoId(videoUrl);
        
        if (videoId) {
            setError('');
            setThumbnailUrl(`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`);
        } else {
            setError('Invalid YouTube URL');
            setThumbnailUrl('');
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = thumbnailUrl;
        link.setAttribute('download', 'thumbnail.jpg');
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
        
        window.open(thumbnailUrl, '_blank');
    };

    return (
        <div className="yt-thumbnail-page">
            <Helmet>
                <title>Free YouTube Thumbnail Downloader | High Quality | YT Copycat</title>
                <meta name="description" content="Download YouTube thumbnails in high quality for free. Easy-to-use thumbnail extractor - no registration required. Get HD thumbnails from any YouTube video instantly." />
                <meta name="keywords" content="youtube thumbnail downloader, download youtube thumbnail, youtube thumbnail extractor, youtube thumbnail hd, youtube thumbnail high quality" />
                <meta property="og:title" content="Free YouTube Thumbnail Downloader - High Quality Images" />
                <meta property="og:description" content="Extract and download high-quality thumbnails from any YouTube video. Free, fast, and no registration required." />
                <meta property="og:type" content="website" />
                <link rel="canonical" href="https://ytcopycat.com/youtube_thumbnail_downloader" />
            </Helmet>

            <Navbar />
            
            <main className="yt-thumbnail-main">
                <div className="yt-thumbnail-container">
                    <div className="yt-thumbnail-header">
                        <h1>
                            YouTube Thumbnail Downloader
                        </h1>
                        <p>
                            Download high-quality thumbnails from any YouTube video for free
                        </p>
                    </div>
                    
                    <div className="yt-thumbnail-form-container">
                        <form onSubmit={handleSubmit}>
                            <div className="yt-thumbnail-input-group">
                                <label htmlFor="videoUrl">YouTube Video URL</label>
                                <input
                                    type="text"
                                    id="videoUrl"
                                    className="yt-thumbnail-input"
                                    value={videoUrl}
                                    onChange={(e) => setVideoUrl(e.target.value)}
                                    placeholder="https://www.youtube.com/watch?v=..."
                                />
                            </div>

                            <button type="submit" className="button-plain">
                                Get Thumbnail
                            </button>
                        </form>

                        {error && <div className="error-message">{error}</div>}

                        {thumbnailUrl && (
                            <div className="yt-thumbnail-result">
                                <h2 className="yt-thumbnail-title">
                                    Thumbnail Preview
                                </h2>
                                <img 
                                    src={thumbnailUrl} 
                                    alt="YouTube video thumbnail" 
                                    className="yt-thumbnail-image"
                                />
                                <div className="yt-thumbnail-actions">
                                    <input
                                        type="text"
                                        value={thumbnailUrl}
                                        readOnly
                                        className="yt-thumbnail-input"
                                    />
                                    <div className="yt-thumbnail-buttons">
                                        <button 
                                            onClick={handleDownload} 
                                            className="button-plain"
                                        >
                                            <i className="fas fa-download"></i> Download Thumbnail
                                        </button> 
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </main>

            <Footer />
        </div>
    );
};

export default YTThumbnail; 