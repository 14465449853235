import React from 'react';
import './Toggle.css';

const Toggle = ({ checked, onChange, label }) => {
    return (
        <div className="toggle-container">
            <label className="toggle-switch">
                <input
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <span className="toggle-slider"></span>
            </label>
            {label && <span className="toggle-label">{label}</span>}
        </div>
    );
};

export default Toggle; 