import React, { useState, useEffect } from 'react';
import api from '../api';
import Popup from '../UI/Popup';
import LoadingButton from '../UI/LoadingButton';
import './Generations.css';

const Generations = () => {
    const [generations, setGenerations] = useState([]);
    const [selectedGeneration, setSelectedGeneration] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSelectionMode, setIsSelectionMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        fetchGenerations();
    }, []);

    const fetchGenerations = async () => {
        try {
            const response = await api.get('/generations');
            if (response.data.result === "OK") {
                setGenerations(response.data.generations);
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching generations:', error);
            setIsLoading(false);
        }
    };

    const getContentTypeIcon = (type) => {
        if (!type) return <i className="fa-solid fa-file-lines"></i>;
        
        switch(type.toLowerCase().trim()) {
            case 'article':
                return <i className="fa-solid fa-newspaper"></i>;
            case 'tweet':
                return <i className="fa-brands fa-twitter"></i>;
            case 'linkedin':
                return <i className="fa-brands fa-linkedin"></i>;
            case 'newsletter':
                return <i className="fa-solid fa-envelope"></i>;
            case 'summary':
                return <i className="fa-solid fa-list-ul"></i>;
            default:
                return <i className="fa-solid fa-file-lines"></i>;
        }
    };

    const handleSelectGeneration = (e, genId) => {
        e.stopPropagation();
        setSelectedItems(prev =>
            prev.includes(genId)
                ? prev.filter(id => id !== genId)
                : [...prev, genId]
        );
    };

    const toggleSelectionMode = () => {
        setIsSelectionMode(!isSelectionMode);
        if (isSelectionMode) {
            setSelectedItems([]);
        }
    };

    const handleDeleteSelected = async () => {
        try {
            setIsDeleting(true);
            for (const genId of selectedItems) {
                await api.delete(`/generations/${genId}`);
            }
            setGenerations(generations.filter(gen => !selectedItems.includes(gen.id)));
            setSelectedItems([]);
            setIsSelectionMode(false);
        } catch (error) {
            console.error('Error deleting generations:', error);
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <div className="generations panel">
            <div className="generations-header">
                <h2><i className="fa-solid fa-file-lines icon"></i> My Generations</h2>
                
                <div className="generations-actions">
                    <div className="toggle-container">
                        <label className="toggle-switch">
                            <input
                                type="checkbox"
                                checked={isSelectionMode}
                                onChange={toggleSelectionMode}
                            />
                            <span className="toggle-slider"></span>
                        </label>
                        <span className="toggle-label">Select items</span>
                    </div>

                    {isSelectionMode && (
                        <LoadingButton
                            className="button-plain delete-button"
                            onClick={handleDeleteSelected}
                            disabled={selectedItems.length === 0}
                            isLoading={isDeleting}
                        >
                            <i className="fas fa-trash icon"></i>
                            Delete ({selectedItems.length})
                        </LoadingButton>
                    )}
                </div>
            </div>

            <div className="generations-list">
                {isLoading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : generations.length === 0 ? (
                    <p className="no-generations">No generations found</p>
                ) : (
                    generations.map(gen => (
                        <div 
                            key={gen.id} 
                            className={`generation-item ${isSelectionMode ? 'selection-mode' : ''}`}
                            onClick={() => !isSelectionMode && setSelectedGeneration(gen)}
                        >
                            {isSelectionMode && (
                                <input
                                    type="checkbox"
                                    className="generation-checkbox"
                                    checked={selectedItems.includes(gen.id)}
                                    onChange={(e) => handleSelectGeneration(e, gen.id)}
                                    onClick={(e) => e.stopPropagation()}
                                />
                            )}
                            <div className="generation-thumbnail">
                                <img 
                                    src={`https://i.ytimg.com/vi/${gen.yt_id}/maxresdefault.jpg`}
                                    alt={gen.yt_title}
                                />
                                <span className="generation-type">{gen.content_type}</span>
                            </div>
                            <div className="generation-info">
                                <div className="generation-title">
                                    <span className="content-type-icon">
                                        {getContentTypeIcon(gen.content_type)}
                                    </span>
                                    <span>{gen.yt_title || 'Untitled'}</span>
                                </div>
                                {gen.summary && (
                                    <div className="generation-summary">
                                        <p>{gen.summary}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>

            {selectedGeneration && (
                <Popup onClose={() => setSelectedGeneration(null)}>
                    <div className="generation-details">
                        <div className="generation-details-header">
                            <div className="header-thumbnail">
                                <img 
                                    src={`https://i.ytimg.com/vi/${selectedGeneration.yt_id}/maxresdefault.jpg`}
                                    alt={selectedGeneration.yt_title}
                                />
                                <a 
                                    href={`https://youtube.com/watch?v=${selectedGeneration.yt_id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="youtube-link"
                                >
                                    Watch on YouTube
                                </a>
                            </div>
                            <div className="header-info">
                                <h3>{selectedGeneration.yt_title || 'Untitled'}</h3>
                                <div className="content-type">
                                    {getContentTypeIcon(selectedGeneration.content_type)} {selectedGeneration.content_type}
                                </div>
                            </div>
                        </div>
                        {selectedGeneration.summary && (
                            <div className="summary-section">
                                <h4>Summary</h4>
                                <p>{selectedGeneration.summary}</p>
                            </div>
                        )}
                        <div className="content-section">
                            <h4>Content</h4>
                            <div 
                                className="content"
                                dangerouslySetInnerHTML={{ __html: selectedGeneration.content }}
                            />
                        </div>
                    </div>
                </Popup>
            )}
        </div>
    );
};

export default Generations;