import React from 'react';
import './UpgradePrompt.css';

const UpgradePrompt = ({ onOpenPricing }) => {
    return (
        <div className="upgrade-prompt">
            <div className="upgrade-content">
                <h2>
                    <i className="fas fa-crown"></i>
                    {" "}Unlock Premium Features
                </h2>
                <p className="subtitle">Get access to our complete suite of AI-powered content generation tools and take your content creation to the next level.</p>
                
                <ul>
                    <li>✓ Higher content generation limits</li>
                    <li>✓ Latest AI models for better content</li>
                    <li>✓ All generation tools</li>
                    <li>✓ 30+ languages support</li>
                    <li>✓ Priority support</li>
                </ul>

                <button className="button-important" onClick={onOpenPricing}>
                    <div className="inner">
                        <i className="fas fa-crown"></i>
                        Upgrade to Premium
                    </div>
                </button>

            </div>
        </div>
    );
};

export default UpgradePrompt; 