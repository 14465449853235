import React, { useState, useContext } from 'react';
import Toggle from '../UI/Toggle';
import LoadingButton from '../UI/LoadingButton';
import api from '../api';
import './PricingPage.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { AuthContext } from '../Login/AuthContext';
import PricingPlans from '../Pricing/PricingPlans';

const PricingPage = () => {
    const { user } = useContext(AuthContext);
    const [isYearly, setIsYearly] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(process.env.REACT_APP_MONTH_PRICE_ID);
    const [checkoutLoading, setCheckoutLoading] = useState(false);

    const handleSubmit = async (id) => {
        if (!user) {
            window.location.href = '/login';
            return;
        }

        try {
            setCheckoutLoading(true);
            const endpoint = id === process.env.REACT_APP_LTD_PRICE_ID ? 'ltd_checkout' : 'checkout';
            const response = await api.post(`/${endpoint}/${id}`);
            window.location.href = response.data.checkout_url;
        } catch (error) {
            console.error('Error during checkout:', error);
        } finally {
            setCheckoutLoading(false);
        }
    };

    return (
        <>
            <Navbar />
            <div className="pricing-page">
                <div className="section">
                    <div className="section-header">
                        <h1>PRICING</h1>
                        <h2>Choose the plan that's right for you</h2>
                        <p>Transform your YouTube content into various formats with our AI-powered tools</p>
                    </div>

                    <PricingPlans
                        isYearly={isYearly}
                        setIsYearly={setIsYearly}
                        selectedPlan={selectedPlan}
                        onPlanSelect={setSelectedPlan}
                        onSubmit={handleSubmit}
                        checkoutLoading={checkoutLoading}
                        user={user}
                        className="pricing-page-plans"
                    />
                </div>
            </div>
            <Footer />
        </>
    );
};

export default PricingPage; 