// BottomMenu.js
import React, { useState, useContext } from 'react';
import './BottomMenu.css';
import { AuthContext } from '../Login/AuthContext';

const BottomMenu = ({ onSelect, selected, onOpenPricing }) => {
  const { subscription } = useContext(AuthContext);
  const [showGeneratorMenu, setShowGeneratorMenu] = useState(false);

  const toggleGeneratorMenu = () => {
    setShowGeneratorMenu(!showGeneratorMenu);
  };

  return (
    <>
      {showGeneratorMenu && (
        <div className="generator-menu">
          <button onClick={() => { onSelect('blog'); setShowGeneratorMenu(false); }}>
            <span className="fas fa-newspaper icon"></span>
            Blog
          </button>
          <button onClick={() => { onSelect('tweet'); setShowGeneratorMenu(false); }}>
            <span className="fab fa-twitter icon"></span>
            Tweet
          </button>
          <button onClick={() => { onSelect('newsletter'); setShowGeneratorMenu(false); }}>
            <span className="fas fa-envelope icon"></span>
            Newsletter
          </button>
          <button onClick={() => { onSelect('summary'); setShowGeneratorMenu(false); }}>
            <span className="fas fa-list icon"></span>
            Summary
          </button>
          <button onClick={() => { onSelect('linkedin'); setShowGeneratorMenu(false); }}>
            <span className="fab fa-linkedin icon"></span>
            LinkedIn
          </button>
        </div>
      )}

      <div className="bottom-menu">
        <button
          className={`button-bottommenu ${showGeneratorMenu ? 'active' : ''}`}
          onClick={toggleGeneratorMenu}
        >
          <span className="fas fa-magic icon"></span>
        </button>
        <button
          className={`button-bottommenu ${selected === 'generations' ? 'active' : ''}`}
          onClick={() => onSelect('generations')}
        >
          <span className="fas fa-history icon"></span>
        </button>
        <button
          className={`button-bottommenu ${selected === 'settings' ? 'active' : ''}`}
          onClick={() => onSelect('settings')}
        >
          <span className="fas fa-cog icon"></span>
        </button>
        {!subscription?.active && (
          <button
            className={`button-bottommenu premium`}
            onClick={onOpenPricing}
          >
            <span className="fas fa-crown icon"></span>
          </button>
        )}
      </div>
    </>
  );
};

export default BottomMenu;
