import React from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';

const TweetGenerator = ({ onOpenPricing }) => {
    const formFields = [
        LanguageSelect(true),
        {
            id: 'emoji',
            type: 'checkbox',
            label: 'Use Emojis'
        },
        {
            id: 'tone',
            type: 'select',
            label: 'Tone',
            defaultValue: 'Friendly',
            options: [
                { value: 'Friendly', label: 'Friendly' },
                { value: 'Inspirational', label: 'Inspirational' },
                { value: 'Entertaining', label: 'Entertaining' },
                { value: 'Persuasive', label: 'Persuasive' },
                { value: 'Analytical', label: 'Analytical' },
                { value: 'Educational', label: 'Educational' },
                { value: 'Narrative', label: 'Narrative' },
                { value: 'Professional', label: 'Professional' }
            ]
        },
        {
            id: 'style',
            type: 'select',
            label: 'Style',
            defaultValue: 'Informative',
            options: [
                { value: 'Promotional', label: 'Promotional' },
                { value: 'Informative', label: 'Informative' },
                { value: 'Engaging', label: 'Engaging' }
            ]
        },
        {
            id: 'thread',
            type: 'checkbox',
            label: 'Twitter thread'
        },
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <Generator
            title={<><span className="highlight">YT</span> to Twitter/X</>}
            icon="fab fa-twitter icon"
            formFields={formFields}
            generateEndpoint="/generate/youtube_tweet"
            transformFormData={(data) => ({
                url: data.url,
                language: data.language,
                emoji: data.emoji || false,
                tone: data.tone,
                style: data.style,
                thread: data.thread || false,
                model: data.model
            })}
            onOpenPricing={onOpenPricing}
        />
    );
};

export default TweetGenerator; 