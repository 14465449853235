import React from 'react';
import Generator from './Generator';
import LanguageSelect from './LanguageSelect';

const NewsletterGenerator = ({ onOpenPricing }) => {
    const formFields = [
        LanguageSelect(false),
        {
            id: 'newsletter_goal',
            type: 'select',
            label: 'Goal',
            defaultValue: 'Entertaining',
            options: [
                { value: 'Entertaining', label: 'Entertaining' },
                { value: 'Informative', label: 'Informative' }
            ]
        },
        {
            id: 'tone',
            type: 'select',
            label: 'Tone',
            defaultValue: 'casual',
            options: [
                { value: 'casual', label: 'Casual' },
                { value: 'familiar', label: 'Familiar' },
                { value: 'formal', label: 'Formal' }
            ]
        },
        {
            id: 'model',
            type: 'select',
            label: 'AI Model',
            defaultValue: 'openai',
            options: [
                { value: 'openai', label: 'OpenAI (GPT-4)' }
            ]
        }
    ];

    return (
        <Generator
            title={<><span className="highlight">YT</span> to Newsletter</>}
            icon="bi bi-envelope-fill icon"
            formFields={formFields}
            generateEndpoint="/generate/youtube_newsletter"
            transformFormData={(data) => ({
                url: data.url,
                language: data.language,
                newsletter_goal: data.newsletter_goal,
                tone: data.tone,
                model: data.model
            })}
            onOpenPricing={onOpenPricing}
        />
    );
};

export default NewsletterGenerator; 