import React from 'react';

const LanguageSelect = () => ({
    id: 'language',
    type: 'select',
    label: 'Language',
    defaultValue: 'english',
    options: [
        { value: 'arabic', label: '🇸🇦 Arabic' },
        { value: 'bengali', label: '🇧🇩 Bengali' },
        { value: 'bulgarian', label: '🇧🇬 Bulgarian' },
        { value: 'chinese', label: '🇨🇳 Chinese' },
        { value: 'croatian', label: '🇭🇷 Croatian' },
        { value: 'czech', label: '🇨🇿 Czech' },
        { value: 'danish', label: '🇩🇰 Danish' },
        { value: 'dutch', label: '🇳🇱 Dutch' },
        { value: 'english', label: '🇬🇧 English' },
        { value: 'finnish', label: '🇫🇮 Finnish' },
        { value: 'french', label: '🇫🇷 French' },
        { value: 'german', label: '🇩🇪 German' },
        { value: 'greek', label: '🇬🇷 Greek' },
        { value: 'hebrew', label: '🇮🇱 Hebrew' },
        { value: 'hindi', label: '🇮🇳 Hindi' },
        { value: 'hungarian', label: '🇭🇺 Hungarian' },
        { value: 'indonesian', label: '🇮🇩 Indonesian' },
        { value: 'italian', label: '🇮🇹 Italian' },
        { value: 'japanese', label: '🇯🇵 Japanese' },
        { value: 'korean', label: '🇰🇷 Korean' },
        { value: 'latvian', label: '🇱🇻 Latvian' },
        { value: 'lithuanian', label: '🇱🇹 Lithuanian' },
        { value: 'malay', label: '🇲🇾 Malay' },
        { value: 'norwegian', label: '🇳🇴 Norwegian' },
        { value: 'persian', label: '🇮🇷 Persian' },
        { value: 'polish', label: '🇵🇱 Polish' },
        { value: 'portuguese', label: '🇵🇹 Portuguese' },
        { value: 'portuguese_br', label: '🇧🇷 Portuguese (Brazil)' },
        { value: 'romanian', label: '🇷🇴 Romanian' },
        { value: 'russian', label: '🇷🇺 Russian' },
        { value: 'serbian', label: '🇷🇸 Serbian' },
        { value: 'slovak', label: '🇸🇰 Slovak' },
        { value: 'slovenian', label: '🇸🇮 Slovenian' },
        { value: 'spanish', label: '🇪🇸 Spanish' },
        { value: 'swedish', label: '🇸🇪 Swedish' },
        { value: 'tamil', label: '🇮🇳 Tamil' },
        { value: 'thai', label: '🇹🇭 Thai' },
        { value: 'turkish', label: '🇹🇷 Turkish' },
        { value: 'ukrainian', label: '🇺🇦 Ukrainian' },
        { value: 'urdu', label: '🇵🇰 Urdu' },
        { value: 'vietnamese', label: '🇻🇳 Vietnamese' }
    ]
});

export default LanguageSelect; 